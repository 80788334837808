<template>
  <div class="page-policy">
    <div class="banner">
      <img src="../assets/images/zhengcejiansuo.jpg" alt="" width="100%">
    </div>
    <div class="search-wrap">
      <el-row class="row-1">
        <el-col :span="4"><div class="label">关键字:</div></el-col>
        <el-col :span="16">
          <el-input v-model="queryParams.searchStr" placeholder="" @keyup.enter.native="search"/>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" class="search-btn" @click="search">搜索</el-button>
        </el-col>
      </el-row>
      <div class="filter-wrap">
        <div class="row row-1">
          <div :class="['all', {active: parentId == ''}]" @click="onAllClick('parentId')">全部</div>
          <div class="list">
            <!--<div class="item">税务筹划(自有数据)</div>
            <div class="item active">税务筹划(自有数据)</div>-->
            <div :class="['item', {active: parentId == item.id}]" v-for="item in Options_1" :key="item.id"
                 @click="chooseItem(item, 'parentId')">{{item.name}}</div>
          </div>
        </div>
        <div class="row row-2" v-if="Options_2.length">
          <div :class="['all', {active: currentId == ''}]" @click="onAllClick('currentId')">全部</div>
          <div class="list">
            <div :class="['item', {active: currentId == item.id}]" v-for="(item, n) in Options_2" :key="n"
                 @click="chooseItem(item, 'currentId')">{{item.name}}</div>
          </div>
        </div>
        <div class="row row-3" v-if="Options_3.length">
          <div :class="['all', {active: sonId == ''}]" @click="onAllClick('sonId')">全部</div>
          <div class="list">
            <div :class="['item', {active: sonId == item.id}]" v-for="(item, n) in Options_3" :key="n"
                 @click="chooseItem(item, 'sonId')">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-loading="loading">
      <div class="rs-list" v-if="dataList.length">
        <div class="list-item" v-for="(item, n) in dataList" :key="n" @click="toDetail(item)">
          <!--<router-link :to="`policyDetail/${item.id}`" tag="p">-->
            <span class="desc">{{item.title}}</span><span class="time">{{moment(item.createTime).format("YYYY-MM-DD hh:mm:ss")}}</span>
          <!--</router-link>-->
        </div>
        <!--<div class="list-item" v-for="n in 6" :key="n">
          <span class="desc">{{item.title}}</span><span class="time">{{item.createTime.substring(0, 10)}}</span>
          <span class="desc">国家税务局关于批发市场交易合同征收印花税问题的通知国税函发〔1992〕1640号</span><span class="time">1992-11-16</span>
        </div>-->
      </div>
      <el-empty :image-size="100" v-else></el-empty>
    </div>
    <div class="row-page">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="pageChange"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'policyRetrieve',
  data () {
    return {
      Options_1: [],
      Options_2: [],
      Options_3: [],
      parentId: '',
      currentId: '',
      sonId: '',
      dataList: [],
      total: 0,
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        searchStr: '',
        categoryId: ''
      },
      loading: false
    }
  },
  methods: {
    pageChange (pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    },
    chooseItem (item, key) {
      if (this[key] === item.id) return
      this[key] = item.id
      if (key === 'parentId') {
        this.currentId = ''
        this.sonId = ''
        this.Options_3 = []
        this.init(item.code, 'Options_2')
      } else if (key === 'currentId') {
        this.sonId = ''
        this.init(item.code, 'Options_3')
      } else if (key === 'sonId') {

      }
      this.queryParams.categoryId = item.id
      this.search()
    },
    onAllClick (key) {
      if (key === 'parentId') {
        this.parentId = ''
        this.currentId = ''
        this.Options_2 = []
        this.sonId = ''
        this.Options_3 = []
        this.queryParams.categoryId = ''
      } else if (key === 'currentId') {
        this.currentId = ''
        this.sonId = ''
        this.Options_3 = []
        this.queryParams.categoryId = this.parentId
      } else if (key === 'sonId') {
        this.sonId = ''
        this.queryParams.categoryId = this.currentId
      }
      this.search()
    },
    init (code, setKey) {
      this.API.getTaxCategory({ code }).then(({ code, data }) => {
        this[setKey] = data
      })
    },
    getList () {
      this.loading = true
      this.API.getPolicySearchPage(this.queryParams).then(({ code, data }) => {
        this.dataList = data.list
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    toDetail (item) {
      window.open(`/#/policyDetail/${item.id}`)
    }
  },
  created () {
    this.init('ZCSJ', 'Options_1')
    this.getList()
  }
}
</script>

<style scoped lang="scss">
.page-policy{
  background: #fff;
  text-align: left;
  .search-wrap{
    width: 960px;
    margin: 30px auto 0;
    .row-1{
      line-height: 40px;
      .label{
        text-align: right;
        margin-right: 14px;
      }
      .search-btn{
        margin-left: 14px;
      }
    }
    .filter-wrap .row{
      display: flex;
      text-align: center;
      margin: 20px 0;
      line-height: 40px;
      .all{
        width: 100px;
        height: 40px;
        border: 1px solid #ddd;
        cursor: pointer;
        &.active{
          background: #20a0ff;
          color: #fff;
          border-color: #20a0ff;
        }
      }
      .list{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .item{
          border: 1px solid #ddd;
          margin: 0 0 10px 30px;
          font-size: 14px;
          padding: 0 20px;
          cursor: pointer;
          min-width: 60px;
          &.active{
            background: #20a0ff;
            color: #fff;
            border-color: #20a0ff;
          }
        }
      }
    }
  }
  .content{
    width: 1200px;
    min-height: 200px;
    margin: 0 auto;
    .rs-list {
      .list-item{
        line-height: 50px;
        border-bottom: 1px dashed #999;
        padding: 10px 0;
        cursor: pointer;
        &:hover{
          color: #20a0ff;
        }
        .desc{
          font-size: 18px;
        }
        .time{
          float: right;
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
  .row-page{
    width: 1200px;
    margin: 30px auto 0;
    text-align: center;
  }
}
</style>
